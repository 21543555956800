import {
  Typography,
  Avatar,
  Modal,
  Box,
  Drawer,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";

import React, { useState, useEffect } from "react";

import axios from "axios";

import "./AvailabilityForm.css";
import BookingForm from "./BookingForm";

import doctor from "./images/doctor.jpg";
import { endPoint } from "./config/const";

// const endPoint = "http://localhost:5000/"; 
// const endPoint = "https://api.iboosthealthcare.com/";
function AvailabilityForm(props) {
  const [dates, setDates] = useState([]);
  const [showform, setShowForm] = useState(false);
  const [timing, setTiming] = useState({});
  const [open, setOpen] = useState(false);
  const [disableTime, setDisableTime] = useState([]);
  const [doctorName, setDotorName] = useState("");
  const [doctorId, setDotorId] = useState('')
  const [doctorConsult, setDotorConsult] = useState()
  const [bookDate, setBookDate] = useState('')
  const [bookTime, setBookTime] = useState('')
  const [table, setTable] = useState([]);
  const [bookingTable, setBookingTable] = useState([])

  // console.log("disableTime", disableTime);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setShowForm(true);
  };
  const handleClickClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    checkAvailabilitydoc();
  }, []);

  // useEffect(() => {
  //   bookingAvailability();
  // }, []);

  // useEffect(() => {
  //   checkAvailability();
  // }, []);

  async function checkAvailability(id) {
    const workingHoursRes = await fetch(
      `${endPoint}meeting/check_availability?doc_id=${id}`,
      {
        method: "GET",
        "Content-Type": "application/json",
      }
    );
    const workingHoursData = await workingHoursRes.json();
    console.log("workingHoursData", workingHoursData);
    bookingAvailability(workingHoursData, id)
    // setDates(workingHoursData);
    setOpen(true);
  }

  async function checkAvailabilitydoc() {
    const workingHoursRes = await fetch(`${endPoint}meeting/check`, {
      method: "GET",
      "Content-Type": "application/json",
    });
    const workingHours = await workingHoursRes.json();
    console.log("workingHour", workingHours);
    setTable(workingHours);
  }

  async function bookingAvailability(workingHoursData, id) {
    const bookWorkingHoursRes = await fetch(`${endPoint}meeting/booking_check`, {
      method: "GET",
      "Content-Type": "application/json",
    });
    const bookWorkingHours = await bookWorkingHoursRes.json();
    for (let i = 0; i < workingHoursData.length; i++) {
      for (let j = 0; j < workingHoursData[i].timing?.length; j++) {
        for (let k = 0; k < bookWorkingHours.length; k++) {
            if (workingHoursData[i].bookTimeString === bookWorkingHours[k].date && 
              workingHoursData[i].timing[j].time === bookWorkingHours[k].time && 
              parseInt(workingHoursData[i].docId) === bookWorkingHours[k].doc_id) {
              workingHoursData[i].timing[j].booked = true;
              break;
            }}
      }
    }
    setDates(workingHoursData);
    // console.log("workingHour", bookWorkingHours);
    // setBookingTable(bookWorkingHours);
  }

  const handleDisable = (obj) => {
    if (disableTime.length > 0) {
      const arr = [...disableTime];
      arr.push(obj);
      setDisableTime(arr);
    } else {
      const arr = [];
      arr.push(obj);
      setDisableTime(arr);
    }
  };
  
const decideDisable = (time, timeString) => {
  const currDateSystem = new Date();
  const currMilli = currDateSystem.getTime();
  const date = new Date(timeString);
  const currTime = time.time.split(":")[0]
  const currDate = date.setHours(currTime);
  // console.log('time',currMilli);
  // console.log('currdate',currDate)
  if ( currMilli > currDate) {
    return true
  }
    if (time.booked) {
      return true;
    } else {
      for (let i = 0; i < disableTime.length; i++) {
        if (
          disableTime[i].dateString === timeString &&
          disableTime[i].time === time.time && disableTime[i].doctorId === doctorId
        ) {
          return true;
        }
      }
    } 
    return false;
  };
  
  console.log("disableTime", disableTime)
  // console.log("bookingTable", bookingTable)

  return (
    <div className="container">

    <Modal
    open={props.booking}
    // onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
   >
    <Box className='form-modalStyle'>
      <Box className='form-container'>
        <Box className='close-videocall-form'>
          <CloseIcon onClick={props.CloseDoctor} fontSize='small' />
        </Box>
        <Box className='form-doctor'>
          <Box className='form-doctor-profile'>
           
          </Box>
          <Box className='form-doctor-details'>
           
           
           
          </Box>
        </Box>
        <Box className='doc-msg'>
         
        </Box>
        <Box className='form-timings'>
        {table.map((item) =>{
          return (
            
            <Card key={item.id} sx={{ maxWidth: 345, margin: '20px', }}>
            <CardMedia
              sx={{ height: 250 }}
              image={doctor}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {item.doctor_name}
              </Typography>
              
            </CardContent>
            <CardActions>
            <Button size="small" 
                onClick={() => {
                  checkAvailability(item.doc_id)
                  setDotorName(item.doctor_name)
                  setDotorId(item.doc_id)
                  setDotorConsult(item.consult_time)
                }}
                >
                Booking
              </Button>
            </CardActions>
          </Card>
          
          )
        })}
      </Box>
      </Box>
    </Box>
  </Modal>
    
    {/*  {table.map((item) =>{
    return (
      
      <Card key={item.id} sx={{ maxWidth: 345, margin: '20px', }}>
      <CardMedia
        sx={{ height: 250 }}
        image={doctor}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item.doctor_name}
        </Typography>
        
      </CardContent>
      <CardActions>
      <Button size="small" 
          onClick={() => {
            checkAvailability(item.doc_id)
            setDotorName(item.doctor_name)
            setDotorId(item.doc_id)
            setDotorConsult(item.consult_time)
          }}
          >
          Booking
        </Button>
      </CardActions>
    </Card>
    
    )
  })}*/}
  
     {/* <Button size="small" onClick={handleOpen}>
        Booking
      </Button> */}

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle">
          <Box className="form-container">
            <Box className="close-videocall-form">
              <CloseIcon onClick={() => handleClose()} fontSize="small" />
            </Box>
            <Box className="doctor">
              <Box className="doctor-profile">
                <Avatar
                  className="doctor-profile-pic"
                  sx={{ width: 80, height: 80 }}
                />
              </Box>
              <Box className="doctor-details">
                <Typography variant="body1">{doctorName}</Typography>
                <Typography variant="body2">Primary Care Doctor</Typography>
                <Typography variant="body2" style={{ color: "gray" }}>
                  112 W 72nd StNew YorkNY10023
                </Typography>
                <Box className="rating">
                  <StarIcon style={{ fontSize: 18, color: "red" }} />
                  <Typography variant="body2">4.81 (1071)</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="doc-msg">
              <Typography variant="body2">
                Choose a time with {doctorName} that works for you
              </Typography>
            </Box>
            <Box className="timings">
              {dates.length > 0
                ? dates.map((date, index) => { 
                    const timeString = `${date.year}-${
                      date.monthNumber < 10
                        ? "0" + date.monthNumber
                        : date.monthNumber
                    }-${date.date < 10 ? "0" + date.date : date.date}`;
                   return (
                     <Box className="timings-container" key={`dates-${index}`}>
                      {date.day !== null ? (
                      <Typography>{`${date.day}, ${date.month} ${date.date}`}</Typography> ) : (<Typography className="no-avail"></Typography>)}
                        <Box className="time-container">
                          {date.timing !== null ? (
                            date.timing.map((time, index) => {
                              return (
                                <button
                                  key={`${time}-${index}`}
                                  variant="contained"
                                  disabled={decideDisable(time, timeString)}
                                  // disabled={time.booked ? true : time.dateString === timeString && time.time === time ? true : false}

                                  className="time"
                                  // style={styles.time}
                                  onClick={() => {
                                    const obj = {
                                      doctorId: doctorId,
                                      date: date.date,
                                      month: date.monthNumber,
                                      year: date.year,
                                      time: time.time,
                                      dateString: `${date.year}-${
                                        date.monthNumber < 10
                                          ? "0" + date.monthNumber
                                          : date.monthNumber
                                      }-${
                                        date.date < 10
                                          ? "0" + date.date
                                          : date.date
                                      }`,
                                    };
                                    setTiming(obj);
                                    handleClickOpen();
                                    // handleDisable(obj);
                                    setBookDate(obj.dateString);
                                    setBookTime(obj.time)
                                  }}
                                >
                                  {time.time}
                                </button>
                              );
                            })
                          ) : (
                            <Typography className="no-avail"></Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })
                : null}
            </Box>
            <BookingForm
              showform={showform}
              handleDisable={() => handleDisable(timing)}
              // onCloseIconPressed={() => setShowForm(false)}
              onCloseIconPressed={handleClickClose}
              doctorName = {doctorName}
              doctorId = {doctorId}
              doctorConsult = {doctorConsult}
              bookDate = {bookDate}
              bookTime = {bookTime}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AvailabilityForm;
