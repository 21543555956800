import {
  Typography,
  Avatar,
  Modal,
  Box,
  InputLabel,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  Button,
  RadioGroup,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import CloseIcon from '@mui/icons-material/Close';

import React, { useState, useRef } from 'react';

import axios from 'axios';

import "./BookingForm.css";
import { endPoint } from "./config/const";

// const endPoint = "https://api.iboosthealthcare.com/";
function BookingForm(props) {

const bookdetail = ({
  bookDate: props.bookDate,
  bookTime: props.bookTime,
})
const [formData, setFormData] = useState({
    docId: props.doctorId, 
    doctorName: props.doctorName,
    doctorConsult: props.doctorConsult,
    bookingForm: {
      // email: "",
      // firstname: "",
      // lastname: "",
      // dob: "",
      // sex: "",
      email: {
        value:'',
        error:false,
        errorMessage:'You must enter a email'
      },
      firstname: {
        value:'',
        error:false,
        errorMessage:'You must enter a firstname'
      },
      lastname: {
        value:'',
        error:false,
        errorMessage:'You must enter a lastname'
      },
      dob: {
        value:'',
        error:false,
        errorMessage:'You must enter a dob'
      },
      sex: {
        value:'',
        error:false,
        errorMessage:'You must choose your sex'
      },
    }
});

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log("name", name, value)
    setFormData((prevState) => ({ ...prevState, 
      bookingForm: {
        ...prevState.bookingForm,
        [name]: {
          ...prevState.bookingForm[name],
          value: value
        }
      } }));
  };

const formBookingCreate = async() => {
  const bookingtable = {
    formData: formData.bookingForm,
    docId: formData.docId, 
    doctorName: formData.doctorName,
    doctorConsult: formData.doctorConsult,
    bookDate: bookdetail.bookDate,
    bookTime: bookdetail.bookTime,
  }
  const response = await axios.post(`${endPoint}meeting/booking_form`, bookingtable);
  console.log(response.data);
  //  setFormData({
  //   username: "",
  //   reason: "",
  //   appointment: "",
  //   phonenumber: "",
  //   notes: "",
  // });
  props.handleDisable()
}

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formFields = Object.keys(formData.bookingForm);
    let newFormValues = {...formData.bookingForm}
    let validationClose = false
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData.bookingForm[currentField].value;
      
      if(currentValue === ''){
        validationClose = true
        newFormValues = {
          ...newFormValues,
          [currentField]:{
            ...newFormValues[currentField],
            error:true
          }
        }
      }

    }

    setFormData({...formData,
      bookingForm: newFormValues
    })
    console.log("newFormValues", newFormValues)
    
    // const bookingtable = {
    //       formData: formData.bookingForm,
    //       docId: formData.docId, 
    //       doctorName: formData.doctorName,
    //       doctorConsult: formData.doctorConsult,
    //       bookDate: bookdetail.bookDate,
    //       bookTime: bookdetail.bookTime,
    //     }
    try {
      // const response = await axios.post(`${endPoint}meeting/booking_form`, bookingtable);
      // console.log(response.data);
      // //  setFormData({
      // //   username: "",
      // //   reason: "",
      // //   appointment: "",
      // //   phonenumber: "",
      // //   notes: "",
      // // });
      // props.handleDisable()
      if(!validationClose) {
      props.onCloseIconPressed()
      formBookingCreate()
      }
    
    } catch (error) {
      console.error('Error inserting data:', error.message);
    }
    // console.log("formData",formData);
  };
 
  // const handleSubmit = async (e) => {
  //   console.log("formData.bookDate", formData.bookTime)
  //   e.preventDefault()
  //   // const doctorName = props.doctorName
  //   const bookingtable = {
  //     formData: formData.bookingForm,
  //     docId: formData.docId, 
  //     doctorName: formData.doctorName,
  //     doctorConsult: formData.doctorConsult,
  //     bookDate: bookdetail.bookDate,
  //     bookTime: bookdetail.bookTime,
  //   }
  //   try {
  //     const response = await axios.post('http://localhost:5000/meeting/bookform', bookingtable);
  //     console.log(response.data);
  //     //  setFormData({
  //     //   username: "",
  //     //   reason: "",
  //     //   appointment: "",
  //     //   phonenumber: "",
  //     //   notes: "",
  //     // });
  //     props.handleDisable()
  //     props.onCloseIconPressed()
    
  //   } catch (error) {
  //     console.error('Error inserting data:', error.message);
  //   }
  //   console.log("formData",formData);
  // };
  console.log("formData", formData)
return (
    <div className='container'>
    <Modal
        open={props.showform}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
        <Box className='form-modalStyle'>
          <Box className='form-container'>
            <Box className='close-videocall-form'>
              <CloseIcon onClick={props.onCloseIconPressed} fontSize='small' />
            </Box>
            <Box className='form-doctor'>
              <Box className='form-doctor-profile'>
                <Avatar
                  className='form-doctor-profile-pic'
                  sx={{ width: 80, height: 80 }}
                />
              </Box>
              <Box className='form-doctor-details'>
                <Typography variant='body1'>{formData.doctorName}</Typography>
                <Typography variant='body2'>Primary Care Doctor</Typography>
                <Typography variant='body2' style={{ color: 'gray' }}>112 W 72nd StNew YorkNY10023</Typography>
                <Typography variant='body2' style={{ color: 'gray', fontSize: '12px' }}>{bookdetail.bookDate},  {bookdetail.bookTime}</Typography>
              </Box>
            </Box>
            <Box className='doc-msg'>
              <Typography variant='body2'>Choose a time with {formData.doctorName} that works for you</Typography>
            </Box>
            <Box className='form-timings'>
            <form>
            <InputLabel style={{ textAlign: "start"}}>Email</InputLabel>
             <TextField
               style={{ width: "500px" }}
               variant="outlined"
               size="small"
               name="email"
               value={formData.bookingForm.email.value}
               error={formData.bookingForm.email.error}
              helperText={formData.bookingForm.email.error && formData.bookingForm.email.errorMessage}
               onChange={handleChange}
             />
             <div style={{ display: "flex", justifyContent: "space-between"}}>
             <div>
            <InputLabel style={{ textAlign: "start"}}>Legal first name</InputLabel>
            <TextField
              style={{ width: "235px" }}
              variant="outlined"
              size="small"
              name="firstname"
              value={formData.bookingForm.firstname.value}
              error={formData.bookingForm.firstname.error}
              helperText={formData.bookingForm.firstname.error && formData.bookingForm.firstname.errorMessage}
              onChange={handleChange}
            />
            </div>
            <div>
            <InputLabel style={{ textAlign: "start"}}>Legal last name</InputLabel>
            <TextField
              style={{ width: "235px" }}
              variant="outlined"
              size="small"
              name="lastname"
              value={formData.bookingForm.lastname.value}
              error={formData.bookingForm.lastname.error}
              helperText={formData.bookingForm.lastname.error && formData.bookingForm.lastname.errorMessage}
              onChange={handleChange}
            />
            </div>
            </div>
            {/*<InputLabel style={{ textAlign: "start"}}>Choose your insurance</InputLabel>
            <FormControl size="small">
            <Select 
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: "480px", margin: "5px" }} 
              variant="outlined"
              size="small"
            >
            </Select>
              </FormControl>*/}
              <InputLabel style={{ textAlign: "start"}}>Date of birth</InputLabel>
                 
              <TextField
              style={{ width: "500px"}}
              type="date"
              variant="outlined"
              size="small"
              name="dob"
              value={formData.bookingForm.dob.value}
              error={formData.bookingForm.dob.error}
              helperText={formData.bookingForm.dob.error && formData.bookingForm.dob.errorMessage}
              onChange={handleChange}
            />
             


              
           {/*   <LocalizationProvider
              dateAdapter={AdapterDayjs}>
              <DemoContainer 
              components={['DateField']}>
                <DateField style={{ width: "480px" }} size="small"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                clearable
                />
              </DemoContainer>
            </LocalizationProvider>*/}
            
           
           {/* <FormControl size="small">
            <Select 
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: "480px", margin: "5px" }} 
              variant="outlined"
            >
            </Select>
              </FormControl> */}
            <InputLabel style={{ textAlign: "start"}}>Sex</InputLabel>
            <FormControl>
           <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="sex"
              value={formData.bookingForm.sex.value}
              error={formData.bookingForm.sex.error}
              helperText={formData.bookingForm.sex.error && formData.bookingForm.sex.errorMessage}
              onChange={handleChange}
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
          </FormControl>
           {/* <Box className="form-box">
            <FormControl >
            <FormControlLabel  value="" control={<Radio color="primary"/>} label="Male" />
            <FormControlLabel value="" control={<Radio color="primary"/>} label="Female" />
            </FormControl>
            </Box>*/}
           {/* <InputLabel style={{ textAlign: "start"}}>Appointment time</InputLabel>
            <TextField
              style={{ width: "480px", margin: "5px" }}
              type="text"
              variant="outlined"
              size="small"
              name="appointment"
              value={formData.bookingForm.appointment}
              onChange={handleChange}
            />
            <InputLabel style={{ textAlign: "start"}}>Phone number where the doctor can contact you</InputLabel>
            <TextField
              style={{ width: "480px", margin: "5px" }}
              type="number"
              variant="outlined"
              size="small"
              name="phonenumber"
              value={formData.bookingForm.phonenumber}
              onChange={handleChange}
            />
            <InputLabel style={{ textAlign: "start"}}>Notes for the doctor's office</InputLabel>
            <TextField
              style={{ width: "480px", margin: "5px" }}
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              variant="outlined"
              name="notes"
              value={formData.bookingForm.notes}
              onChange={handleChange}
            />*/}
            <Button type="submit" variant="contained" onClick={(e) => handleSubmit(e)} style={{ width: "480px", margin: "5px", textTransform: 'none',backgroundColor: '#1f4e5c' }}>
              Book Appointment
            </Button>
          </form>
          </Box>
          </Box>
        </Box>
      </Modal>
               
    </div>
  );
}

export default BookingForm;







