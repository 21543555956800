import React, { useState, useEffect } from 'react'
import { Button } from "@mui/material";

import AvailabilityForm from './AvailabilityForm';
const App = () => {
  const [booking, setBooking] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setBooking(true);
  };
  const handleClose = () => {
    setBooking(false);
  };

  
  
  return (
    <div>  
    {open ?  null :
    <Button size="small" style={{ color: 'white', textTransform: 'none',backgroundColor: '#1f4e5c' }} onClick={() => {
      window.parent.postMessage(
        {
          isOpen: !open,
        },
        "*"
      );
      setOpen(!open);
    }}>
        Booking Online
      </Button>  }
      <AvailabilityForm 
      booking = {open} 
      CloseDoctor={()=>{
        window.parent.postMessage(
          {
            isOpen: !open,
          },
          "*"
        );
        setOpen(!open);
        // setOpen(!open)
      }} />

</div>
  )
}

export default App


// useEffect(() => {
  //   (function (d, script) {
  //     script = d.createElement("script");
  //     script.type = "text/javascript";
  //     script.src = "https://chat.iboostweb.com/widget.js";
  //     script.setAttribute("id", "iboost-chat-script");
  //     script.setAttribute("data-account", "9bypO3Ua9N");
  //     d.getElementsByTagName("head")[0].appendChild(script);
  //   })(document);

  //   (function (d, iframe) {
  //     var account = d
  //       .querySelector("#iboost-chat-script")
  //       .getAttribute("data-account");
  //     var siteName = window.location.host;
  //     iframe = d.createElement("iframe");
  //     iframe.src = https://chat.iboostweb.com/front?sitename=${siteName}&data-account=${account};
  //     iframe.setAttribute(
  //       "style",
  //       "width:100%!important;height:100%!important;position:fixed!important;left:0!important;right:0!important;top:0!important;bottom:0!important;z-index:999999"
  //     );
  //     d.body.appendChild(iframe);
  //   })(document);
  // }, []);

  // console.log(
  //   "idddd",
  //   document?.querySelector("#iboost-chat-script")?.getAttribute("data-account")
  // );



 